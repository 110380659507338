@import "styles/colors.scss";
@import "styles/media.scss";

.wrapper {
  padding: 2rem 20%;
  @include tablet {
    padding: 2rem 10%;
  }
  @include mobile {
    padding: 1rem;
  }
}

.clientCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: $gray;
  border-radius: 1rem;

  &Title {
    color: $primary;
    font-weight: 500;
    font-size: 1rem;
  }
}
