@import "styles/colors.scss";
@import "styles/media.scss";

.form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  padding: 2rem;
  background: $gray;
  border-radius: 1rem;

  &.loading {
    opacity: 0.5;
  }

  @include mobile {
    padding: 1rem;
  }

  input,
  label,
  ::placeholder {
    @include mobile {
      font-size: 0.8rem;
    }
  }

  textarea::-webkit-scrollbar {
    @include mobile {
      display: none;
    }
  }
}

.controls {
  display: flex;
  gap: 10px;
  width: 100%;
}

.datepicker {
  input {
    padding: 9px 14px !important;
    @include mobile {
      padding: 9px 5px !important;
    }
  }

  button {
    @include mobile {
      padding: 3px !important;
    }
  }
}
