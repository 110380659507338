$tablet: 990px;
$mobile: 550px;

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}
