$DeepSpaceSparkle: #3f6a73;
$DarkSlateGray: #285059;
$MediumAquamarine: #5ce6b8;
$Eucalyptus: #3dccb4;

$primary: $DeepSpaceSparkle;
$secondary: $DarkSlateGray;
$tretiary: $Eucalyptus;
$accent: $MediumAquamarine;

$black: #000;
$white: #fff;
$gray: #f3f3f3;
